<template>
  <div class="ProjectEditTeamForm">
    <!-- left column - members -->
    <div class="ProjectEditTeamForm__members">
      <h4 class="ProjectEditTeamForm__subtitle">
        {{ isFrigate ? $t('project.ApiaryMembers') : $t('project.HiveMembers') }}
      </h4>
      <AppTextField
        v-show="canEditAllRules"
        ref="quickSearch"
        v-model="quickSearch"
        outlined
        dense
        :placeholder="$t('project.Member')"
        hide-details
        class="ProjectEditTeamForm__search-panel-autocomplete rounded-1"
        style="margin: 16px 0"
      >
        <template #append>
          <v-select
            v-model="permissionSelectInSearch"
            :items="roleItems"
            :item-text="(role)=>$t(role.label)"
            return-object
            height="40px"
            dense
            hide-details
            full-width
            class="ProjectEditTeamForm__search-panel-permission-select"
            :menu-props="{ zIndex: 204 }"
            @click.native.stop.prevent="/* task#921 no focus in main field */"
          />
        </template>
      </AppTextField>
      <div
        ref="scrollable"
        class="ProjectEditTeamForm__scrollable"
      >
        <div
          v-if="isBrig && remoteUsersLoading"
          class="ProjectEditTeamForm__loading"
        >
          <div>{{ $t('project.LoadingRemoteMembersM') }}</div>
          <TypingLoader />
        </div>
        <div
          v-else-if="pollError"
          class="ProjectEditTeamForm__error"
        >
          <EmptyState
            :img-src="require('@/assets/images/empty-abstract-parallelepiped.svg')"
            :img-width="266"
            :img-height="253"
            :title="$t('project.ConnectionError')"
          >
            <template #actions>
              <v-btn
                color="primary"
                :loading="pollTimeoutId != null"
                :disabled="pollTimeoutId != null"
                @click="startPolling()"
              >
                {{ $t('project.Retry') }}
              </v-btn>
            </template>
          </EmptyState>
        </div>
        <TeamMember
          v-for="user in filteredUsersToInvite"
          :key="user.id"
          :user="user"
          :disabled="actionInProgress"
          class="ProjectEditTeamForm__member pl-6 pr-0"
          :class="{ 'ProjectEditTeamForm__member--disabled': !canEditAllRules }"
          @click="canEditAllRules && addTeamMember(user)"
        >
          <template v-if="canEditAllRules" #append>
            <v-list-item-action class="my-0 pr-4">
              <v-btn
                icon
                :disabled="actionInProgress"
                @mousedown.stop
                @touchstart.stop
                @click.stop="addTeamMember(user)"
              >
                <v-icon v-text="'mdi-plus'" />
              </v-btn>
            </v-list-item-action>
          </template>
        </TeamMember>

        <v-list-item v-if="usersToInvite != null && usersToInvite.length === 0">
          <v-list-item-content>
            <v-list-item-title>{{ $t('project.NoUsersToInviteM') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('project.AllInvitedM') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else-if="filteredUsersToInvite != null && filteredUsersToInvite.length === 0">
          <v-list-item-content>
            <v-list-item-title>{{ $t('project.NoUsersMatchM') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('project.NoUsersMatchQueryM', { quickSearch }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </div>
    <!-- right column - team -->
    <div class="ProjectEditTeamForm__team">
      <h4 class="ProjectEditTeamForm__subtitle">
        {{ isFrigate ? $t('project.ApiaryProjectTeam') : $t('project.HiveProjectTeam') }}
      </h4>
      <div
        v-if="!isFrigate"
        class="ProjectEditTeamForm__warning pt-4"
      >
        {{ $t('project.ChangeCounterpartTeamM') }}
      </div>
      <div
        ref="scrollable"
        class="ProjectEditTeamForm__scrollable mt-4"
      >
        <TeamDialogPermissions
          v-if="permissions"
          :permissions="permissions"
          :readonly="!canEditSomeRules"
          :readonly-editors="!canEditEditors"
          :readonly-assignees="!canEditAssignees"
          :readonly-readonly="!canEditReadonly"
          :loading="actionInProgress"
          :owner-not-allowed="!projectExists"
          class="ProjectEditTeamForm__team-dialog"
          @set-permission="setPermission"
          @remove-member="removeMember($event.user.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'

import {
  PROJECT_PERMISSION_LEVEL as PERM,
  SERVER_TYPE,
} from '../constants'

import Project from '@/store/orm/project'

import EmptyState from './EmptyState'
import TeamDialogPermissions from './TeamDialogPermissions'
import TeamMember from './TeamMember'
import TypingLoader from './TypingLoader'

const MISSING_PERMISSION = Symbol('missing-permission')

const initProject = (v) => {
  const { projectId } = v
  return projectId != null
    ? Project.find(v.projectId) || null
    : null
}

export default {
  name: 'ProjectEditTeamForm',

  components: {
    EmptyState,
    TeamDialogPermissions,
    TeamMember,
    TypingLoader,
  },

  props: {
    appConnectionId: { type: String, default: null },
    projectId: { type: String, default: null },
    platform: { type: String, required: true },
  },

  data() {
    return {
      quickSearch: '',
      actionInProgress: false,
      permissionSelectInSearch: PERM.EDITOR,
      project: initProject(this),
      pollError: false,
      pollTimeoutId: null,
      remoteUsers: null,
      remoteUsersLoading: false,
    }
  },

  computed: {

    isBrig() { return this.platform === SERVER_TYPE.brig },

    isFrigate() { return this.platform === SERVER_TYPE.frigate },

    projectExists() {
      return this.projectId != null
    },

    roleItems() {
      const currentFirst = R.sortWith([R.ascend(perm => perm.label)])
      return this.projectExists
        ? currentFirst([PERM.READONLY, PERM.EDITOR, PERM.OWNER])
        : currentFirst([PERM.READONLY, PERM.EDITOR])
    },

    currentUser() {
      const { $store } = this
      return $store.getters['user/current']
    },

    permissions() {
      const { $store, project, projectId } = this
      return this.projectExists
        ? $store.getters['permission/forProject'](projectId)
        : project && project.projectPermissions
    },

    allActiveUsers() {
      const { $store } = this
      if (this.isFrigate) {
        const users = $store.getters['user/active']
        return users && users.map(user => ({
          ...user,
          _searchBy: [
            user.firstName,
            user.lastName,
            user.userLogin,
            user.userEmail,
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        }))
      } else {
        return this.remoteUsers && this.remoteUsers.map(user => ({
          ...user,
          userLogin: user.login,
          userEmail: user.email,
          firstName: user.login,
          _searchBy: [
            user.email,
            user.login,
          ]
            .filter(Boolean)
            .map(s => s.toLocaleLowerCase())
            .join(' '),
        }))
      }
    },
    usersToInvite() {
      const { allActiveUsers: users, permissions } = this
      if (users == null) return null
      if (permissions == null) return users

      const memberIds = permissions.map(perm => perm.userId)
      return users.filter(user => !memberIds.includes(user.id))
    },

    searchTokens() {
      const { quickSearch } = this
      return quickSearch
        .split(/\s+?/g)
        .map(s => s.toLocaleLowerCase())
        .filter(Boolean)
    },

    filteredUsersToInvite() {
      const { usersToInvite: users, searchTokens } = this
      if (!searchTokens.length) return users
      return users && users.filter(({ _searchBy }) =>
        searchTokens.every(q => _searchBy.includes(q)))
    },

    currentUserPermissionLevel() {
      const { currentUser, permissions } = this
      if (!currentUser || !permissions) return null
      return permissions
        .find(perm => perm.userId === currentUser.id)?.level ||
        MISSING_PERMISSION
    },

    canEditSomeRules() {
      // Admin can edit all rules
      // Owner can edit all rules
      // Editor can edit assignees and can leave the project
      // Assignee can only leave project
      const { currentUser, currentUserPermissionLevel } = this

      if (!currentUser) return null

      const editorPerms = [PERM.EDITOR.value, PERM.OWNER.value]
      return currentUser.isAdmin || editorPerms.includes(currentUserPermissionLevel)
    },

    canEditAllRules() {
      const { currentUser, currentUserPermissionLevel } = this

      if (!currentUser) return null
      return currentUser.isAdmin || currentUserPermissionLevel === PERM.OWNER.value
    },

    canEditEditors() { return this.canEditAllRules },
    canEditReadonly() { return this.canEditAllRules },
    canEditAssignees() {
      const { canEditAllRules, canEditSomeRules, currentUserPermissionLevel } = this
      return canEditAllRules ||
        (canEditSomeRules && currentUserPermissionLevel === PERM.EDITOR.value)
    },
  },

  watch: {
    projectId: {
      immediate: true,
      handler(projectId) {
        if (projectId == null) {
          const userOwner = R.clone(this.currentUser)
          this.project = {
            projectPermissions: this.isBrig
              ? []
              : [{ id: userOwner.id, issues: [], level: PERM.OWNER.value, projectId: null, user: userOwner, userId: userOwner.id }],
          }
        } else {
          this.fetchPermissions()
        }
        this.reset()
      },
    },

  },

  created() {
    this.$store.dispatch('user/getList')
    if (this.isBrig) {
      this.startPolling()
    }
  },

  beforeDestroy() {
    this.stopPolling()
  },

  methods: {

    fetchRemoteUsers(forceUpdate = false) {
      const { $store, appConnectionId } = this
      if (!appConnectionId) throw new Error(this.$t('project.ErrorConnectionNotLoadedM'))
      return $store.dispatch('appConnections/getRemoteUsers', { applicationConnectId: this.appConnectionId, forceUpdate })
    },

    async fetchRemoteUsersTick(forceUpdate = true) {
      try {
        const response = await this.fetchRemoteUsers(forceUpdate)
        this.pollError = false
        if (response.status === 'READY') {
          this.remoteUsers = R.clone(response.data)
          this.remoteUsersLoading = false
        }
        if (this.pollTimeoutId || response.status === 'PENDING') {
          this.pollTimeoutId = setTimeout(() => this.fetchRemoteUsersTick(false), 3000)
        }
      } catch (e) {
        this.pollError = true
        this.remoteUsersLoading = false
      } finally {
        this.pollTimeoutId = null
      }
    },

    stopPolling() {
      clearTimeout(this.pollTimeoutId)
      this.pollTimeoutId = null
    },

    startPolling() {
      this.stopPolling()
      this.remoteUsersLoading = true
      this.pollTimeoutId = setTimeout(this.fetchRemoteUsersTick, 0)
    },

    createPermission(user, selectedLevel) {
      return { id: user.id, issues: [], level: selectedLevel, projectId: null, user: user, userId: user.id }
    },

    reset() {
      this.quickSearch = ''
      this.$nextTick(() => this.$refs.scrollable?.scroll?.(0, 0))
    },

    fetchPermissions() {
      this.$store.dispatch('permission/getProjectPermissions', {
        projectId: this.projectId,
      })
    },

    action(promise) {
      this.actionInProgress = true
      return promise.finally(() => { this.actionInProgress = false })
    },

    async closeInvite() {
      this.invite = false
      this.quickSearch = ''
      await this.$nextTick()
      this.$refs.quickSearch.$el.querySelector('input').blur()
    },

    async confirmPermissionChange(permissionLevel) {
      const { $store } = this

      if (permissionLevel === PERM.OWNER.value) {
        return await $store.dispatch('confirm/openDialog', {
          title: this.$t('project.ChangeOwnerQ'),
          subtitle: this.$t('project.ChangeOwnerM'),
          consentLabel: this.$t('project.ChangeOwner'),
        })
      }

      if (permissionLevel === PERM.EDITOR.value) {
        return await $store.dispatch('confirm/openDialog', {
          title: this.$t('project.MakeEditorQ'),
          subtitle: this.$t('project.EditorM'),
          consentLabel: this.$t('project.MakeEditor'),
        })
      }

      return true
    },

    async setPermission({ user: { id: userId }, level: permissionLevel }) {
      const { $store, projectId, projectExists } = this

      const agreed = await this.confirmPermissionChange(permissionLevel)
      if (!agreed) return

      if (projectExists) {
        const actionPayload = { projectId, userId, level: permissionLevel }
        const permissionUpdated = $store.dispatch('permission/updateProjectPermission', actionPayload)
        await this.action(permissionUpdated)
      } else {
        const newOne = R.clone(this.project.projectPermissions)
        const updatedPermissions = R.curry((value, userId, items) => R.map(R.when(R.propEq('userId', userId), R.assoc('level', value)), items))
        this.project.projectPermissions = updatedPermissions(permissionLevel, userId, newOne)
      }
    },

    async removeMember(userId) {
      const { $store, projectId, projectExists } = this
      if (projectExists) {
        const deleted = $store.dispatch('permission/deleteProjectPermission', { projectId, userId })
        await this.action(deleted)
      } else {
        const newOne = R.clone(this.project.projectPermissions.filter(permission => permission.userId !== userId))
        this.project.projectPermissions = newOne
      }
    },

    async addTeamMember(user) {
      const { $store, projectId, projectExists } = this
      if (projectExists) {
        const created = $store.dispatch('permission/createProjectPermission', {
          projectId,
          userId: user.id,
          level: this.permissionSelectInSearch.value,
        })
        await this.action(created)
      } else {
        const userWithPermission = R.clone(user)
        const newPermission = this.createPermission(userWithPermission, this.permissionSelectInSearch.value)
        this.project.projectPermissions.push(newPermission)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.ProjectEditTeamForm
  display: flex
  height: 100%

  &__search-panel
    position: sticky
    top: 0
    padding: 24px 24px 4px
    background: white
    width: 100%
    z-index: 1
    margin: 0 1px // not to overflow the box-shadow

    &-permission-select
      font-size: 13px
      width: 90px
      margin-top: -8px

      ::v-deep
        .v-input__slot:after
          display: none

        .v-input__slot:before
          display: none

        .v-select__selection--comma
          position: absolute
          right: 20px

  &--dense &__search-panel
    padding-top: 0

  &__team-dialog
    ::v-deep
      .TeamMember
        padding: 24px

  &__subtitle
    font-size: 20px
    font-weight: 500
    line-height: 26px
    color: #68648B

  &__warning
    color: #A09EB9
    font-size: 14px
    font-weight: 400
    line-height: 24px

  &__scrollable
    // max-height: 416px
    overflow: hidden auto
    height: calc(100% - 76px)

  &__loading
    width: 100%
    margin-top: 200px
    display: flex
    flex-flow: column
    align-items: center
    justify-content: space-between
    height: 60px
    font-size: 20px
    font-weight: 500
    color: #A09EB9
    text-align: center

  &__error
    width: 100%
    margin-top: 70px
    text-align: center

  &__error-message
      font-size: 18px
      font-weight: 500

  &__members
    flex: 2 1 0
    padding: 28px 16px
    border-right: 1px solid #EDEEF5
    overflow: hidden
    width: 424px

  &__member
    &--disabled
      cursor: auto
      &:hover
        background-color: #FFFFFF
      &::before
        background-color: #FFFFFF

  &__team
    flex: 1 1 0
    padding: 28px 16px
    width: 424px

</style>
