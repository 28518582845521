<template>
  <div class="ProjectEdit">
    <AppBarLayout>
      <template #header>
        {{ title }}
      </template>
    </AppBarLayout>
    <div class="ProjectEdit__page-content">
      <div class="ProjectEdit__left-column">
        <div v-if="isAppConnectProjectCreationAvailable && !projectExists">
          <v-radio-group
            v-model="connectionType"
            class="EditCard__track-radio-group"
            row
          >
            <v-radio
              value="appConnection"
              :label="$t('connection.AppConnection')"
              class="EditCard__track-radio"
            />

            <v-radio
              value="projectConnection"
              :label="$t('connection.ProjectConnection')"
              class="EditCard__track-radio"
            />
          </v-radio-group>
          <v-expand-transition>
            <AppSelect
              v-if="connectionType === CONNECT.app"
              v-model="selectedAppConnectionId"
              :items="appConnectionsList"
              style="width:315px"
              outlined
              dense
              item-value="id"
              item-text="name"
              :placeholder="$t('project.SelectAppConnection')"
            >
              <template #item="{ item }">
                <div class="d-flex align-center text-truncate">
                  <ConnectionStatus
                    class="flex-shrink-0"
                    style="margin-right: 6px"
                    :connection-type="CONNECT.app"
                    :connection-id="item.id"
                    :connection-name="item.name"
                    :last-incoming-pong="item.lastIncomingPong"
                    :last-outgoing-ping="item.lastOutgoingPing"
                    platform-name="frigate"
                  />
                  <div class="text-truncate">
                    {{ item.name }}
                  </div>
                </div>
              </template>
              <template #selection="{ item }">
                <div class="d-flex align-center text-truncate">
                  <ConnectionStatus
                    class="flex-shrink-0"
                    style="margin-right: 6px"
                    :connection-type="CONNECT.app"
                    :connection-id="item.id"
                    :connection-name="item.name"
                    :last-incoming-pong="item.lastIncomingPong"
                    :last-outgoing-ping="item.lastOutgoingPing"
                    platform-name="frigate"
                  />
                  <div class="text-truncate">
                    {{ item.name }}
                  </div>
                </div>
              </template>
            </AppSelect>
          </v-expand-transition>
        </div>
        <ProjectEditConnectionInfo
          v-if="projectExists && project?.hawserID != null"
          :project-id="projectId"
          :is-app-connection="project && project.applicationConnect != null"
        />
        <ProjectEditForm
          ref="projectEditForm"
          :app-connection-id="selectedAppConnectionId"
          :connection-type="connectionType"
          :project-id="projectId"
        />
      </div>
      <v-window
        v-model="windowStep"
        class="ProjectEdit__right-column"
      >
        <v-window-item
          :value="STEPS.APIARY_TEAM"
          class="ProjectEdit__window-item"
        >
          <ProjectEditTeamForm
            ref="projectEditTeamForm"
            :project-id="projectId"
            :platform="SERVER_TYPE.frigate"
          />
        </v-window-item>
        <v-window-item
          :value="STEPS.HIVE_TEAM"
          class="ProjectEdit__window-item"
        >
          <ProjectEditTeamForm
            ref="projectEditRemoteTeamForm"
            :app-connection-id="selectedAppConnectionId"
            :platform="SERVER_TYPE.brig"
          />
        </v-window-item>
      </v-window>
    </div>
    <div class="ProjectEdit__footer">
      <v-btn
        v-if="isShowNextButton"
        color="primary"
        depressed
        :disabled="saving || connectionType === CONNECT.app && !selectedAppConnectionId"
        :loading="saving"
        @click="nextStep"
      >
        {{ $t('project.Next') }}
      </v-btn>
      <v-btn
        v-if="isShowDoneButton"
        color="primary"
        depressed
        :disabled="saving"
        :loading="saving"
        @click="applyChanges"
      >
        {{ $t('project.SaveProject') }}
      </v-btn>
      <v-btn
        class="ml-4"
        color="primary"
        outlined
        depressed
        :disabled="saving"
        @click="resetForm"
      >
        {{ $t('project.Cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import * as R from 'ramda'
import moment from 'moment'

import { ConnectionStatus } from '@hexway/shared-front'

import { EnumRemoteUserProjectPermissionSchemaLevel } from '../api'
import { PROJECT_PERMISSION_LEVEL as PERM, PROJECT_CONNECTION_TYPE as CONNECT, SERVER_TYPE } from '../constants'

import Project from '../store/orm/project'

import AppBarLayout from '../layouts/AppBarLayout'
import ProjectEditConnectionInfo from '../components/ProjectEditConnectionInfo'
import ProjectEditForm from '../components/ProjectEditForm'
import ProjectEditTeamForm from '../components/ProjectEditTeamForm'

const STEPS = Object.freeze({
  APIARY_TEAM: 'EDIT__PROJECT__STEPS__APIARY_TEAM',
  HIVE_TEAM: 'JEDIT__PROJECT__STEPS__HIVE_TEAM',
})

const STEP_NUMBER = {
  [STEPS.APIARY_TEAM]: 1,
  [STEPS.HIVE_TEAM]: 2,
}

export default {
  name: 'ProjectEdit',

  components: {
    AppBarLayout,
    ConnectionStatus,
    ProjectEditForm,
    ProjectEditTeamForm,
    ProjectEditConnectionInfo,
  },

  props: {
    projectId: { type: String, default: null },
    appConnectionId: { type: String, default: null },
  },

  data() {
    return {
      CONNECT,
      STEPS,
      SERVER_TYPE,

      saving: false,
      windowStep: STEPS.APIARY_TEAM,
      isAppConnectProjectCreationAvailable: false,
      connectionType: this.appConnectionId ? CONNECT.app : CONNECT.project,
      selectedAppConnectionId: this.appConnectionId,
    }
  },

  computed: {

    title() {
      if (STEP_NUMBER[this.windowStep] === 2) {
        const name = this.appConnections.find(connection => connection.id === this.selectedAppConnectionId).name
        return this.$t('project.AppConnectionNewProject', { appConnectionName: name })
      }
      return this.projectId ? this.$t('project.EditProject') : this.$t('project.NewProject')
    },

    currentUser() { return this.$store.getters['user/current'] },

    isAdmin() { return this.currentUser?.isAdmin },

    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },

    projectExists() { return this.projectId != null },

    appConnections() {
      return this.isAdmin
        ? this.$store.getters['appConnections/appConnections']
        : []
    },

    appConnectionsList() { return this.appConnections.map(connection => R.assoc('disabled', !(connection.lastIncomingPong && this.duration(connection) < 12 * 60 * 1000), connection)) },

    totalSteps() {
      if (this.connectionType === CONNECT.project || (this.connectionType === CONNECT.app && this.projectExists)) { return Object.values(STEPS).length - 1 }

      return Object.values(STEPS).length
    },

    currentStepNumber() { return STEP_NUMBER[this.windowStep] },

    isShowNextButton() {
      const { currentStepNumber, totalSteps } = this

      return totalSteps > currentStepNumber
    },

    isShowDoneButton() {
      const { currentStepNumber, totalSteps } = this

      return currentStepNumber >= totalSteps
    },

  },

  watch: {
    connectionType: {
      immediate: true,
      handler(connectionType) {
        if (connectionType === CONNECT.project) {
          this.windowStep = STEPS.APIARY_TEAM
        }
      },
    },

    projectId: {
      immediate: true,
      handler(projectId) {
        if (projectId) {
          this.connectionType = this.project?.applicationConnect ? CONNECT.app : CONNECT.project
        }
      },
    },
  },

  async created() {
    if (this.isAdmin) {
      await this.$store.dispatch('appConnections/getAppConnections')
      this.isAppConnectProjectCreationAvailable = Object.values(this.$store.state.appConnections.appConnections).length > 0
    }
  },

  methods: {

    duration(connection) {
      const now = new Date().valueOf()
      const { lastIncomingPong, lastOutgoingPing } = connection

      return moment.duration(moment(now).diff(moment(lastIncomingPong || lastOutgoingPing))).asMilliseconds()
    },

    nextStep() {
      const { windowStep } = this

      if (windowStep === STEPS.APIARY_TEAM) this.windowStep = STEPS.HIVE_TEAM
    },

    resetForm() {
      this.$router.go(-1)
      this.windowStep = STEPS.APIARY_TEAM
    },

    async applyChanges() {
      if (this.saving) throw new Error('Request is already pending')
      this.saving = true
      const localUsers = this.$refs.projectEditTeamForm.permissions.filter(permission => permission.level !== PERM.OWNER.value).map(permission => ({ level: permission.level, userID: permission.userId }))
      const remoteUsers = this.$refs.projectEditRemoteTeamForm?.permissions.filter(permission => permission.level !== PERM.OWNER.value).map(permission => ({ level: this.mapRemotePermissionLevel(permission.level), login: permission.user.login }))
      this.$refs.projectEditForm.validateAndSubmit(localUsers, remoteUsers)
      this.saving = false
    },

    mapRemotePermissionLevel(level) {
      if (level === PERM.EDITOR.value) return EnumRemoteUserProjectPermissionSchemaLevel.EDIT
      if (level === PERM.READONLY.value) return EnumRemoteUserProjectPermissionSchemaLevel.READONLY
    },

  },

}
</script>

<style lang="sass" scoped>
.ProjectEdit
  background: white
  height: calc(100vh - 56px)

  ::v-deep .v-select__selections
    flex-wrap: nowrap

  @media #{map-get($display-breakpoints, 'md-and-up')}
    height: calc(100vh - 64px)

  &__page-content
    height: calc(100% - 76px)
    overflow: auto
    display: flex
    flex-wrap: nowrap
    align-items: stretch
    position: relative

  &__left-column
    flex: 1 1 754px
    padding: 28px 32px
    overflow: hidden auto
    text-overflow: ellipsis
    border-right: 1px solid #EDEEF5

  &__right-column
    flex: 1 1 auto
    height: 100%

  &__window-item
    height: 100%

  &__members
    flex: 1 1 0
    padding: 28px 16px
    background: red
    border-right: 1px solid #EDEEF5

  &__team
    flex: 1 1 0
    padding: 28px 16px

  &__footer
    height: 76px
    width: 100%
    padding: 18px 32px
    display: flex
    align-items: center
    justify-content: flex-end

    background: #F5F5F9 // Neutral/97
    border-top: 1px solid #E6E6F2

</style>
