<template>
  <div class="TeamDialogPermissions">
    <TeamMember
      v-for="permission in permissions"
      :key="permission.id"
      :user="permission.user"
      :level="permission.level"
    >
      <template #append>
        <v-list-item-action>
          <TeamMemberRole
            :permission-level="permission.level"
            :disabled="isDisabled(permission)"
            :owner-not-allowed="ownerNotAllowed"
            :loading="loading"
            @set-permission="$emit('set-permission', { user: permission.user, level: $event.value })"
            @remove-member="$emit('remove-member', { user: permission.user })"
          />
        </v-list-item-action>
      </template>
    </TeamMember>
  </div>
</template>

<script>
import { PROJECT_PERMISSION_LEVEL as PERM } from '../constants'

import TeamMember from './TeamMember'
import TeamMemberRole from './TeamMemberRole'

export default {
  name: 'TeamDialogPermissions',

  components: {
    TeamMember,
    TeamMemberRole,
  },

  props: {
    permissions: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    readonlyEditors: { type: Boolean, default: false },
    readonlyAssignees: { type: Boolean, default: false },
    // sounds silly, but it means that readonly members are readonly (editing disabled)
    readonlyReadonly: { type: Boolean, default: false },
    ownerNotAllowed: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  methods: {
    isDisabled(permission) {
      const { readonly, readonlyEditors, readonlyAssignees, readonlyReadonly } = this
      return (
        readonly ||
        permission.level === PERM.OWNER.value ||
        (readonlyEditors && permission.level === PERM.EDITOR.value) ||
        (readonlyAssignees && permission.level === PERM.LIMITED.value) ||
        (readonlyReadonly && permission.level === PERM.READONLY.value)
      )
    },
  },
}
</script>
