<template>
  <AppSelect
    v-model="model"
    class="ProjectGroupSelect"
    :items="filteredItems"
    item-text="name"
    item-value="id"
    :outlined="outlined"
    :dense="dense"
    :placeholder="placeholder"
    :loading="rootGroup == null || loading"
    v-bind="$attrs"
    v-on="$listeners"
    @focus="quickSearch = ''; $emit('focus', $event)"
  >
    <template #prepend-item>
      <v-list-item
        class="pa-0"
        style="border-bottom: 2px solid #E6E6F2; position: sticky; top: 0; z-index: 2; background: white"
      >
        <AppTextField
          v-model="quickSearch"
          :label="$t('project.SearchGroup')"
          outlined
          dense
          class="rounded-0"
          style="border-color: transparent"
          hide-details
          margins-with-hidden-details="mb-0"
          transparent-borders
          append-icon="mdi-magnify"
        />
      </v-list-item>
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        v-bind="attrs"
        v-on="on"
      >
        <div :style="{ paddingLeft: (truncateLevel(item.lvl) * 32) + 'px' }">
          <v-icon
            class="mr-2"
            v-text="item.instance.getIcon(!item.instance.isEmpty)"
          />
          {{ item.instance.isRoot ? 'Root' : item.name }}
        </div>
      </v-list-item>
    </template>

    <template #selection="{ item }">
      <div class="d-flex align-center text-truncate">
        <v-icon
          class="mr-2"
          v-text="item.instance.getIcon(false)"
        />
        <div
          class="text-truncate"
          v-text="item.instance.getPath().map(g => g.isRoot ? 'Root' : g.name).join('/')"
        />
      </div>
    </template>
  </AppSelect>
</template>

<script>
import ProjectGroup from '../store/orm/projectGroup'
import i18n from '../i18n'

export default {
  name: 'ProjectGroupSelect',

  inheritAttrs: false,

  props: {
    rootGroupId: { type: String, default: null }, // root group UUID for items
    value: { type: String, default: null }, // group UUID
    placeholder: { type: String, default: i18n.t('project.Location') },
    outlined: { type: Boolean, default: true },
    dense: { type: Boolean, default: true },
    loading: { type: Boolean, default: null },
  },

  data() {
    return {
      model: this.value,
      quickSearch: '',
    }
  },

  computed: {
    rootGroup() {
      const { rootGroupId } = this
      const q = rootGroupId
        ? ProjectGroup.query().where('id', rootGroupId)
        : ProjectGroup.getRootGroupQ()
      return q.with('projects').first()
    },

    items() {
      const { rootGroup } = this
      if (!rootGroup) return []

      const items = []
      const addGroupRecursive = (group, lvl = 0) => {
        items.push({
          ...group,
          instance: group,
          lvl,
          _searchIndex: this.getSearchIndex(group),
        })
        for (const child of group.getChildrenQ().orderBy('name').all()) {
          addGroupRecursive(child, lvl + 1)
        }
      }
      addGroupRecursive(rootGroup)

      return items
    },

    filteredItems() {
      const { items, quickSearch } = this
      const searchTokens = quickSearch.split(/\s+?/g).map(x => x.trim().toLowerCase()).filter(Boolean)
      if (!searchTokens.length) return items
      const isSelected = item =>
        this.model == null
          ? item.instance.isRoot
          : item.id === this.model
      return items.filter(item => isSelected(item) || searchTokens.some(q => item._searchIndex.includes(q)))
    },
  },

  watch: {
    value(v) {
      if (v !== this.model) {
        this.model = v
        this.quickSearch = ''
      }
    },
    model(v) {
      if (v !== this.value) {
        this.$emit('input', v)
        this.quickSearch = ''
      }
    },

    'rootGroup.id': {
      immediate: true,
      handler(gid) { if (this.model == null && gid != null) { this.model = gid } },
    },
  },

  mounted() {
    ProjectGroup.dispatch('$getTree')
  },

  methods: {
    getSearchIndex(group) {
      return group.getAllDescendants({
        includeSelf: true,
        includeProjects: false,
      })
        .map(group => group.name.trim().toLowerCase())
        .join(' ')
    },

    truncateLevel(lvl) { return Math.min(lvl, 4) },
  },
}
</script>

<style lang="sass">
.ProjectGroupSelect
  .v-select__selections
    flex-wrap: nowrap
</style>
