import moment from 'moment'
import { req, ref } from 'vuelidate/lib/validators/common'

const getMomentComparisonValidator = momentCmpMethod =>
  locator =>
    function(value, vm) {
      const thisDate = req(value) && moment(value)
      if (!thisDate || !thisDate.isValid()) return false

      const otherValue = ref(locator, this, vm)
      const otherDate = req(otherValue) && moment(otherValue)
      if (!otherDate || !otherDate.isValid()) return false

      return thisDate[momentCmpMethod](otherDate)
    }

// Moment comparison validators. Usage example:
// { // Vue-component
//   data: () => ({
//     startDate: '2020-01-01',
//     endDate: '2019-12-13',
//   }),
//   validations: {
//     startDate: { isBefore: isBeforeMoment('endDate') },
//     endDate: { isAfter: isAfterMoment(vm => vm.startDate) },
//   },
// }
export const isAfterMoment = getMomentComparisonValidator('isAfter')
export const isBeforeMoment = getMomentComparisonValidator('isBefore')
export const isSameMoment = getMomentComparisonValidator('isSame')
export const isSameMomentOrAfter = getMomentComparisonValidator('isSameOrAfter')
export const isSameMomentOrBefore = getMomentComparisonValidator('isSameOrBefore')
