<template>
  <div class="TypingLoader" />
</template>

<script>
export default {
  name: 'TypingLoader',
}
</script>

<style lang="sass" scoped>
@keyframes TypingLoader__line
  0%
    background-color: rgba(33, 150, 243, 1)
    box-shadow: 12px 0px 0px 0px rgba(33, 150, 243,0.2),24px 0px 0px 0px rgba(33, 150, 243,0.2)
  25%
    background-color: rgba(33, 150, 243, 0.4)
    box-shadow: 12px 0px 0px 0px rgba(33, 150, 243,2),24px 0px 0px 0px rgba(33, 150, 243,0.2)
  75%
    background-color: rgba(33, 150, 243, 0.4)
    box-shadow: 12px 0px 0px 0px rgba(33, 150, 243,0.2),24px 0px 0px 0px rgba(33, 150, 243,2)

.TypingLoader
    width: 6px
    height: 6px
    border-radius: 50%
    animation: TypingLoader__line 1s linear infinite alternate

</style>
