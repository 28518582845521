<template>
  <div class="ProjectEditConnectionInfo">
    <div
      v-if="isAppConnection"
      style="display:flex"
    >
      <div class="ProjectEditConnectionInfo__item-vertical">
        <div
          class="ProjectEditConnectionInfo__label"
          v-text="$t('connection.AppConnection')"
        />
        <ConnectionStatus
          class="mt-1"
          :connection-type="CONNECT.app"
          :connection-id="project.applicationConnect.id"
          :connection-name="project.applicationConnect.name"
          :last-incoming-pong="project.applicationConnect.lastIncomingPong"
          :last-outgoing-ping="project.applicationConnect.lastOutgoingPing"
          show-connection-name
          platform-name="frigate"
        />
      </div>
      <div class="ProjectEditConnectionInfo__item-vertical">
        <div
          class="ProjectEditConnectionInfo__label"
          v-text="$t('connection.AppConnectionId')"
        />
        <v-hover v-slot="{ hover }">
          <div class="d-flex align-center">
            <div class="ProjectEditConnectionInfo__label__text">{{ project.applicationConnect.id }}</div>
            <v-btn
              icon
              small
              class="ProjectEditConnectionInfo__label__btn"
              :class="{ 'ProjectEditConnectionInfo__label__btn--hidden': !hover }"
              @click="copyToClipboard(project.applicationConnect.id)"
            >
              <v-icon
                small
                v-text="'mdi-content-copy'"
              />
            </v-btn>
          </div>
        </v-hover>
      </div>
      <div class="ProjectEditConnectionInfo__item-vertical">
        <div
          class="ProjectEditConnectionInfo__label"
          v-text="$t('connection.ProjectConnectionId')"
        />
        <v-hover v-slot="{ hover }">
          <div class="d-flex align-center">
            <div class="ProjectEditConnectionInfo__label__text">{{ project.hawserID }}</div>
            <v-btn
              icon
              small
              class="ProjectEditConnectionInfo__label__btn"
              :class="{ 'ProjectEditConnectionInfo__label__btn--hidden': !hover }"
              @click="copyToClipboard(project.hawserID)"
            >
              <v-icon
                small
                v-text="'mdi-content-copy'"
              />
            </v-btn>
          </div>
        </v-hover>
      </div>
    </div>
    <div
      v-else
      style="display:flex"
    >
      <div class="ProjectEditConnectionInfo__item-horizontal mr-8">
        <div
          class="ProjectEditConnectionInfo__label"
          v-text="$t('connection.ProjectConnection')"
        />
        <ConnectionStatus
          :connection-id="project.hawserID"
          :connection-name="project.connectionName"
          :last-incoming-pong="project.lastIncomingPong"
          :last-outgoing-ping="project.lastOutgoingPing"
          show-connection-name
          platform-name="frigate"
        />
      </div>
      <div class="ProjectEditConnectionInfo__item-horizontal">
        <div
          class="ProjectEditConnectionInfo__label"
          v-text="$t('connection.Id')"
        />
        <v-hover v-slot="{ hover }">
          <div class="d-flex align-center">
            <div class="ProjectEditConnectionInfo__label__text">{{ project.hawserID }}</div>
            <v-btn
              icon
              small
              class="ProjectEditConnectionInfo__label__btn"
              :class="{ 'ProjectEditConnectionInfo__label__btn--hidden': !hover }"
              @click="copyToClipboard(project.hawserID)"
            >
              <v-icon
                small
                v-text="'mdi-content-copy'"
              />
            </v-btn>
          </div>
        </v-hover>
      </div>
    </div>
  </div>
</template>

<script>
import copyToClipboard from 'copy-to-clipboard'

import Project from '../store/orm/project'

import { ConnectionStatus } from '@hexway/shared-front'
import { PROJECT_CONNECTION_TYPE as CONNECT } from '../constants'

export default {
  name: 'ProjectEditConnectionInfo',

  components: {
    ConnectionStatus,
  },

  props: {
    isAppConnection: { type: Boolean, default: false },
    projectId: { type: String, required: true },
  },

  data() {
    return {
      CONNECT,
    }
  },

  computed: {
    project() {
      const { projectId } = this
      return projectId && Project.find(projectId)
    },
  },

  methods: {
    copyToClipboard(id) {
      copyToClipboard(id)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('connection.Copied'),
      })
    },
  },

}
</script>

<style lang="sass" scoped>
.ProjectEditConnectionInfo
  display: flex

  ::v-deep .ConnectionStatus__name
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis

  &__item
    &-horizontal
      display: flex
      align-items: center
    &-vertical
      text-overflow: ellipsis
      margin-right: 30px
      max-width: 150px

  &__label
    color: #41415A
    font-size: 14px
    line-height: 20px
    font-weight: 500
    margin-right: 8px

    &__text
       overflow: hidden
       white-space: nowrap
       text-overflow: ellipsis

    &__btn
      &--hidden
        visibility: hidden
</style>
