import { render, staticRenderFns } from "./ProjectEditTeamForm.vue?vue&type=template&id=04d10026&scoped=true&"
import script from "./ProjectEditTeamForm.vue?vue&type=script&lang=js&"
export * from "./ProjectEditTeamForm.vue?vue&type=script&lang=js&"
import style0 from "./ProjectEditTeamForm.vue?vue&type=style&index=0&id=04d10026&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d10026",
  null
  
)

export default component.exports